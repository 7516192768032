import type { ModeConfig } from '../types';

export const GAME_MODES: Record<string, ModeConfig> = {
  classic: {
    name: 'Classic Mode',
    description: '60 seconds to solve as many problems as you can. Build streaks for multipliers!',
    color: 'blue',
    initialTime: 60,
    useMultiplier: true
  },
  endless: {
    name: 'Endless Mode',
    description: 'Ultra-challenging mode with aggressive time pressure. Each second counts!',
    color: 'green',
    initialTime: 15,
    useMultiplier: true,
    timeRewards: {
      normal: 1.5,
      quick: 2,
      penalties: {
        wrong: -4,
        slow: -2,
        consecutiveSlow: -1,
        missedStreak: -0.5
      }
    },
    progressiveTime: {
      '30': { normal: -0.5, quick: -0.5 },   // After 30 seconds
      '45': { normal: -1.0, quick: -1.0 },   // After 45 seconds
      '60': { normal: -1.5, quick: -1.5 }    // After 60 seconds
    },
    difficultyProgression: {
      '0': { maxDigits: 1 },      // Start: single digit
      '20': { maxDigits: 2 },     // After 20s: two digits
      '35': { numbers: 3 },       // After 35s: three numbers
      '45': { minDigits: 2 },     // After 45s: min two digits
      '60': { allDoubleDigits: true }  // After 60s: all double digits
    },
    survivalBonus: {
      '45': 1.25,  // 25% bonus after 45 seconds
      '60': 1.5    // 50% bonus after 60 seconds
    }
  },
  speed: {
    name: 'Speed Round',
    description: 'Test your quick thinking! 3 seconds per question, but one wrong answer ends the game.',
    color: 'red',
    initialTime: 3,
    timePerQuestion: 3,
    endOnWrong: true,
    useMultiplier: false
  }
};