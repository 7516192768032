import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase credentials');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseKey);

interface ScoreData {
  score: number;
  mode: string;
  device_id: string;
  initials: string;
  streak: number;
  problems_solved: number;
  accuracy: number;
}

export async function fetchLeaderboard(mode: string, timeRange: 'all' | 'week' | 'today' = 'all') {
  let query = supabase
    .from('scores')
    .select('id, score, mode, device_id, initials, streak, problems_solved, accuracy, created_at')
    .eq('mode', mode)
    .order('score', { ascending: false })
    .limit(100);

  if (timeRange === 'week') {
    query = query.gte('created_at', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString());
  } else if (timeRange === 'today') {
    query = query.gte('created_at', new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString());
  }

  const { data, error } = await query;

  if (error) {
    console.error('Error fetching leaderboard:', error);
    throw error;
  }

  return data;
}

export async function submitScore(scoreData: ScoreData) {
  const { data, error } = await supabase
    .from('scores')
    .insert([scoreData])
    .select()
    .single();

  if (error) {
    console.error('Error submitting score:', error);
    throw error;
  }

  return data;
}
