import { useState, useEffect } from 'react';
import type { GameState, GameMode, UserPreferences } from '../types';
import { GAME_MODES } from '../config/modes';

const DEFAULT_PREFERENCES: UserPreferences = {
  initials: '',
  soundEnabled: true,
  achievements: [],
  lastPlayed: '',
  settings: {
    fontSize: 'medium',
    highContrast: false,
    colorblindMode: false,
    reducedAnimations: false,
    volume: 0.5
  }
};

export function useGameState() {
  // Load preferences from localStorage
  const [preferences, setPreferences] = useState<UserPreferences>(() => {
    const saved = localStorage.getItem('preferences');
    return saved ? JSON.parse(saved) : DEFAULT_PREFERENCES;
  });

  // Game state management
  const [gameState, setGameState] = useState<{
    screen: 'menu' | 'playing' | 'paused' | 'ended';
    mode: GameMode | null;
    showSettings: boolean;
    showLeaderboard: boolean;
    showAchievements: boolean;
    isLoading: boolean;
  }>({
    screen: 'menu',
    mode: null,
    showSettings: false,
    showLeaderboard: false,
    showAchievements: false,
    isLoading: false
  });

  // Save preferences to localStorage
  useEffect(() => {
    localStorage.setItem('preferences', JSON.stringify(preferences));
  }, [preferences]);

  const updatePreferences = (updates: Partial<UserPreferences>) => {
    setPreferences(prev => ({ ...prev, ...updates }));
  };

  const updateSettings = (updates: Partial<UserPreferences['settings']>) => {
    setPreferences(prev => ({
      ...prev,
      settings: { ...prev.settings, ...updates }
    }));
  };

  const setScreen = (screen: typeof gameState.screen) => {
    setGameState(prev => ({ ...prev, screen }));
  };

  const setMode = (mode: GameMode | null) => {
    setGameState(prev => ({ ...prev, mode }));
  };

  const toggleSettings = () => {
    setGameState(prev => ({ ...prev, showSettings: !prev.showSettings }));
  };

  const toggleLeaderboard = () => {
    setGameState(prev => ({ ...prev, showLeaderboard: !prev.showLeaderboard }));
  };

  const toggleAchievements = () => {
    setGameState(prev => ({ ...prev, showAchievements: !prev.showAchievements }));
  };

  return {
    preferences,
    gameState,
    updatePreferences,
    updateSettings,
    setScreen,
    setMode,
    toggleSettings,
    toggleLeaderboard,
    toggleAchievements
  };
}