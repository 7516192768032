// Math validation utilities with debug logging

/**
 * Validates a user's answer against the expected result
 * @param userAnswer The user's input answer
 * @param expectedAnswer The correct answer
 * @param debug Whether to log validation steps
 * @returns boolean indicating if the answer is correct
 */
export function validateAnswer(
  userAnswer: number | string,
  expectedAnswer: number,
  debug = false
): boolean {
  if (debug) {
    console.group('Answer Validation');
    console.log('Raw user answer:', userAnswer, typeof userAnswer);
    console.log('Expected answer:', expectedAnswer, typeof expectedAnswer);
  }

  // Handle invalid input
  if (userAnswer === '' || userAnswer === null || userAnswer === undefined) {
    if (debug) {
      console.log('Invalid input: empty or null');
      console.groupEnd();
    }
    return false;
  }

  // Ensure we're comparing numbers, not strings
  const normalizedUser = Number(String(userAnswer).trim());
  const normalizedExpected = Number(expectedAnswer);

  if (debug) {
    console.log('Normalized user answer:', normalizedUser, typeof normalizedUser);
    console.log('Normalized expected:', normalizedExpected, typeof normalizedExpected);
    console.log('Is equal?', normalizedUser === normalizedExpected);
    console.groupEnd();
  }

  if (isNaN(normalizedUser)) {
    return false;
  }

  // Strict numerical equality comparison
  return normalizedUser === normalizedExpected;
}

/**
 * Calculates points for an answer including multipliers and bonuses
 * @param isCorrect Whether the answer was correct
 * @param multiplier Current score multiplier
 * @param isQuick Whether the answer was given quickly
 * @returns Total points earned or lost
 */
export function calculatePoints(
  isCorrect: boolean,
  multiplier: number = 1,
  isQuick: boolean = false
): number {
  const basePoints = isCorrect ? 10 : -5;
  const speedBonus = isCorrect && isQuick ? 5 : 0;
  return Math.round((basePoints + speedBonus) * multiplier);
}

/**
 * Validates and sanitizes numeric input
 * @param input Raw input string
 * @param debug Whether to log sanitization steps
 * @returns Parsed number or null if invalid
 */
export function sanitizeNumericInput(input: string, debug = false): number | null {
  if (debug) {
    console.group('Input Sanitization');
    console.log('Raw input:', input, typeof input);
  }

  // Remove whitespace and validate
  const cleaned = input.trim();
  const parsed = Number(cleaned);

  if (debug) {
    console.log('Cleaned input:', cleaned);
    console.log('Parsed number:', parsed, typeof parsed);
    console.log('Is valid?', !isNaN(parsed));
    console.groupEnd();
  }

  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}