import { useState, useCallback, useEffect } from 'react';
import type { GameState, Problem, GameStats, GameMode } from '../types';
import { GAME_MODES } from '../config/modes';
import { validateAnswer } from '../utils/validation';
import { submitScore as submitScoreToDb } from '../lib/supabase';

const INITIAL_STATS: GameStats = {
  score: 0,
  streak: 0,
  problemsSolved: 0,
  problemsAttempted: 0,
  level: 1,
  multiplier: 1,
  soundEnabled: true,
  perfectStreak: 0,
};

const INITIAL_STATE: GameState = {
  isPlaying: false,
  isPaused: false,
  timeLeft: 60,
  stats: INITIAL_STATS,
  currentProblem: generateProblem(1),
  problems: [],
  mode: 'classic',
  isOffline: false
};

function generateProblem(level: number, mode: GameMode, gameTime: number = 0): Problem {
  console.group('Problem Generation');
  let num1: number, num2: number, num3: number | undefined;
  
  // Get mode config
  const modeConfig = GAME_MODES[mode];
  
  // For endless mode, use time-based difficulty
  if (mode === 'endless') {
    const difficulty = modeConfig.difficultyProgression;
    const timeThresholds = Object.keys(difficulty)
      .map(Number)
      .sort((a, b) => b - a)
      .find(threshold => gameTime >= threshold);
    
    const settings = difficulty[timeThresholds?.toString() || '0'];
    
    // Generate numbers based on difficulty settings
    const getNumber = (min: number = 1, max: number = 9) => 
      Math.floor(Math.random() * (max - min + 1)) + min;
    
    if (settings.allDoubleDigits) {
      num1 = getNumber(10, 99);
      num2 = getNumber(10, 99);
      if (settings.numbers === 3) num3 = getNumber(10, 99);
    } else {
      const minDigits = settings.minDigits || 1;
      const maxDigits = settings.maxDigits || 2;
      const min = Math.pow(10, minDigits - 1);
      const max = Math.pow(10, maxDigits) - 1;
      
      num1 = getNumber(min, max);
      num2 = getNumber(min, max);
      if (settings.numbers === 3) num3 = getNumber(min, max);
    }
  } else {
  switch (level) {
    case 1: // Single digit
      num1 = Math.floor(Math.random() * 9) + 1;
      num2 = Math.floor(Math.random() * 9) + 1;
      console.log('Level 1 - Single digit:', { num1, num2 });
      break;
    case 2: // Numbers up to 20
      num1 = Math.floor(Math.random() * 20) + 1;
      num2 = Math.floor(Math.random() * 20) + 1;
      console.log('Level 2 - Up to 20:', { num1, num2 });
      break;
    case 3: // Three number addition
      num1 = Math.floor(Math.random() * 20) + 1;
      num2 = Math.floor(Math.random() * 20) + 1;
      num3 = Math.floor(Math.random() * 20) + 1;
      console.log('Level 3 - Three numbers:', { num1, num2, num3 });
      break;
    default:
      num1 = Math.floor(Math.random() * 9) + 1;
      num2 = Math.floor(Math.random() * 9) + 1;
      console.log('Default - Single digit:', { num1, num2 });
  }
  }

  const answer = num3 ? num1 + num2 + num3 : num1 + num2;
  console.log('Expected answer:', answer);
  console.groupEnd();

  return {
    num1,
    num2,
    num3,
    answer,
  };
}

export function useGame() {
  const [state, setState] = useState<GameState>(INITIAL_STATE);
  const [highScores, setHighScores] = useState<Record<GameMode, number>>(() => {
    const saved = localStorage.getItem('highScores');
    return saved ? JSON.parse(saved) : {};
  });
  const [deviceId] = useState(() => localStorage.getItem('device_id') || crypto.randomUUID());
  const [feedback, setFeedback] = useState<{ isCorrect: boolean | null; show: boolean; points?: number } | null>(null);
  const [lastAnswerTime, setLastAnswerTime] = useState<number | null>(null);
  const [showLevelUp, setShowLevelUp] = useState(false);

  const submitScore = useCallback(async (initials: string) => {
    try {
      const data = await submitScoreToDb({
        score: state.stats.score,
        mode: state.mode,
        device_id: deviceId,
        initials,
        streak: state.stats.streak,
        problems_solved: state.stats.problemsSolved,
        accuracy: (state.stats.problemsSolved / state.stats.problemsAttempted) * 100
      });
      return data;
    } catch (error) {
      console.error('Error submitting score:', error);
      throw error;
    }
  }, [state.stats, state.mode, deviceId]);

  const togglePause = useCallback(() => {
    setState(prev => ({ ...prev, isPaused: !prev.isPaused }));
  }, []);

  const toggleSound = useCallback(() => {
    setState(prev => ({
      ...prev,
      stats: { ...prev.stats, soundEnabled: !prev.stats.soundEnabled }
    }));
  }, []);


  const startGame = useCallback((mode: GameMode = 'classic') => {
    const modeConfig = GAME_MODES[mode];
    const firstProblem = generateProblem(1);
    setState({
      isPlaying: true,
      isPaused: false,
      timeLeft: modeConfig.initialTime,
      stats: INITIAL_STATS,
      currentProblem: firstProblem,
      problems: [],
      mode,
      isOffline: false
    });
    setFeedback(null);
    setLastAnswerTime(Date.now());
  }, []);

  const endGame = useCallback(() => {
    setState(prev => ({ ...prev, isPlaying: false }));
    
    // Update high scores
    if (state.stats.score > (highScores[state.mode] || 0)) {
      const newHighScores = { ...highScores, [state.mode]: state.stats.score };
      setHighScores(newHighScores);
      localStorage.setItem('highScores', JSON.stringify(newHighScores));
    }
  }, [state.stats.score, state.mode, highScores]);

  const submitAnswer = useCallback((userAnswer: number) => {
    console.group('Speed Mode Answer Submission');
    const isSpeedMode = state.mode === 'speed';
    const modeConfig = GAME_MODES[state.mode];

    console.log('Current state:', {
      timeLeft: state.timeLeft,
      mode: state.mode,
      isPlaying: state.isPlaying
    });

    const now = Date.now();
    const timeTaken = lastAnswerTime ? (now - lastAnswerTime) / 1000 : 3;
    const isQuickAnswer = timeTaken < 2;

    // Validate answer
    const isCorrect = validateAnswer(userAnswer, state.currentProblem.answer);

    // Calculate points
    const basePoints = isCorrect ? 10 : -5;
    const speedBonus = isQuickAnswer ? 5 : 0;
    const pointsEarned = Math.round((basePoints + (isCorrect ? speedBonus : 0)));

    // Log timing details
    console.log('Timing:', {
      timeTaken,
      isQuickAnswer,
      lastAnswerTime,
      isCorrect
    });

    setState(prev => {
      // In Speed Mode, reset timer to initial value on correct answer
      const newTimeLeft = isSpeedMode && isCorrect
        ? modeConfig.timePerQuestion || 3
        : prev.timeLeft;

      const newProblemsSolved = prev.stats.problemsSolved + (isCorrect ? 1 : 0);
      
      console.log('New state:', {
        newTimeLeft,
        newProblemsSolved,
        shouldEndGame: isSpeedMode && !isCorrect
      });

      const answeredProblem = { ...prev.currentProblem, userAnswer, isCorrect };

      const newStats = {
        score: Math.max(0, prev.stats.score + pointsEarned),
        streak: isCorrect ? prev.stats.streak + 1 : 0,
        problemsSolved: newProblemsSolved,
        problemsAttempted: prev.stats.problemsAttempted + 1,
        level: prev.stats.level,
        multiplier: 1,
        perfectStreak: isCorrect ? (prev.stats.perfectStreak || 0) + 1 : prev.stats.perfectStreak,
        soundEnabled: prev.stats.soundEnabled,
      };

      return {
        ...prev,
        timeLeft: newTimeLeft,
        stats: newStats,
        currentProblem: generateProblem(newStats.level, prev.mode),
        problems: [...prev.problems, answeredProblem],
      };
    });

    console.groupEnd();

    // Show feedback
    setFeedback({
      isCorrect,
      show: true,
      points: pointsEarned
    });
    setTimeout(() => setFeedback(null), 1000);
    setLastAnswerTime(Date.now());

    // Only end game on wrong answer in speed mode
    if (isSpeedMode && !isCorrect) {
      console.log('Ending speed mode game due to wrong answer');
      setTimeout(endGame, 1000);
    }
  }, [state.mode, state.currentProblem, lastAnswerTime, endGame]);

  // Timer effect
  useEffect(() => {
    if (!state.isPlaying || state.isPaused) return;

    console.log('Timer effect:', {
      timeLeft: state.timeLeft,
      mode: state.mode,
      isPlaying: state.isPlaying
    });

    const timer = setInterval(() => {
      setState(prev => ({
        ...prev,
        timeLeft: prev.timeLeft - 1,
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, [state.isPlaying, state.isPaused]);

  // Auto-end game when time's up
  useEffect(() => {
    if (state.timeLeft === 0) {
      console.log('Ending game due to time up:', {
        mode: state.mode,
        timeLeft: state.timeLeft
      });
      endGame();
    }
  }, [state.timeLeft, endGame]);

  return {
    state,
    highScores,
    showLevelUp,
    newAchievement: null,
    submitScore,
    startGame,
    endGame,
    togglePause,
    toggleSound,
    submitAnswer,
    feedback,
  };
}